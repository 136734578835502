import type { FromTypes } from '@/components/funnel/payment/Payment';
import ShareASalePixel from '@/utils/shareASalePixel';
import WebGainsPixel from '@/utils/webGainsPixel';
import { useAppSelector } from './useRedux';

declare global {
    interface Window {
        dataLayer: any;
    }
}

interface IFromProps {
    from: FromTypes;
}

function useTracking() {
    const planId = useAppSelector((state) => state.plan.currentPlan);
    const totalPrice = useAppSelector((state) => state.booking.totalPrice);
    const bookingTransactionId = useAppSelector((state) => state.booking.transactionId);
    const giftTransactionId = useAppSelector((state) => state.gift.transactionId);
    const { existingDiscount, discountCode } = useAppSelector((state) => state.discount);
    const giftCardQuantity = useAppSelector((state) => state.gift.giftCardAmount);
    const giftCardPrice = useAppSelector((state) => state.gift.giftCardPrice);
    const currencyName = useAppSelector((state) => state.booking.currency.name);
    const email = useAppSelector((state) => state.travellers.adults[0].email);
    const phone = useAppSelector((state) => state.travellers.adults[0].phone);
    const trackEvents = import.meta.env.VITE_APP_ENV === 'prod';

    const trackStartFunnelEvent = (from: string) => {
        const isFromGiftCard = from === 'giftCard';
        const isFromGiftTravel = from === 'giftTravel';

        const getProductName = () => {
            if (isFromGiftCard) return 'Gift Card';
            if (isFromGiftTravel) return `Gift Travel: Plan ${planId}`;
            return `Booking: Plan ${planId}`;
        };

        if (!trackEvents) return;
        window.dataLayer.push({
            event: 'add_to_cart',
            transaction_id: bookingTransactionId,
            currency: currencyName,
            value: Number(totalPrice),
            items: [
                {
                    id: getProductName(),
                },
            ],
            email: '',
            phone: '',
        });
    };

    const trackArrivalToPaymentPage = ({ from }: IFromProps) => {
        if (!trackEvents) return;

        const isFromBooking = from === 'booking';
        const transactionId = isFromBooking ? bookingTransactionId : giftTransactionId;

        window.dataLayer.push({
            event: 'lead',
            transaction_id: transactionId,
            currency: currencyName,
            value: Number(totalPrice),
            lead_type: 'Arrival to payment page',
        });
    };

    const trackPurchaseEvent = ({ from }: IFromProps) => {
        if (!trackEvents) return;

        const isFromBooking = from === 'booking';
        const isFromGiftCard = from === 'giftCard';
        const isFromGiftTravel = from === 'giftTravel';

        const getProductName = () => {
            if (isFromGiftCard) return 'Gift Card';
            if (isFromGiftTravel) return `Gift Travel: Plan ${planId}`;
            return `Booking: Plan ${planId}`;
        };

        const getProductCategory = () => {
            if (isFromGiftCard) return 'Gift Card';
            if (isFromGiftTravel) return `Gift Travel`;
            return `Booking`;
        };

        const transactionId = isFromBooking ? bookingTransactionId : giftTransactionId;

        ShareASalePixel.trackPurchase(totalPrice, transactionId, currencyName, discountCode);

        WebGainsPixel.trackPurchase(totalPrice, transactionId, currencyName, discountCode);

        window.dataLayer.push({
            event: 'purchase',
            transaction_id: transactionId,
            value: Number(totalPrice),
            currency: currencyName,
            ...(existingDiscount && { coupon: discountCode }),
            items: [
                {
                    id: getProductName(),
                    item_name: getProductName(),
                    item_category: getProductCategory(),
                    price: isFromGiftCard ? `${giftCardPrice}` : `${totalPrice}`,
                    quantity: isFromGiftCard ? giftCardQuantity : 1,
                },
            ],
            email,
            phone: `+${phone}`,
        });
    };

    const ShareASalePixelImg = ({ isFromBooking }: { isFromBooking: boolean }) => {
        const orderID = isFromBooking ? bookingTransactionId : giftTransactionId;
        const amount = Number(totalPrice).toFixed(2);
        const merchantID = '156839';
        return (
            <img
                id="_SHRSL_img_1"
                src={`https://www.shareasale.com/sale.cfm?tracking=${orderID}&amount=${amount}&merchantID=${merchantID}&transtype=sale&couponcode=${discountCode}&currency=${currencyName}`}
                width="1"
                height="1"
                alt=""
            />
        );
    };

    return {
        trackPurchaseEvent,
        trackStartFunnelEvent,
        trackArrivalToPaymentPage,
        ShareASalePixelImg,
    };
}

export default useTracking;
