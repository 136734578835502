import { lazy, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import PaymentConfirm from './components/funnel/paymentConfirm/PaymentConfirm';
import CountryRedirect from './components/globals/countryRedirect/CountryRedirect';
import DummyHeader from './components/globals/header/DummyHeader';
import Header from './components/globals/header/Header';
import ScrollToTop from './components/globals/scrollToTop/ScrollToTop';
import useChatWidget from './hooks/useChatWidget';
import useHelmet from './hooks/useHelmet';
import usePlan from './hooks/usePlan';
import useRakuten from './hooks/useRakuten';
import useRoutes from './hooks/useRoutes';
import useTracking from './hooks/useTracking';

const Home = lazy(() => import('./components/pages/home/Home'));
const Footer = lazy(() => import('./components/globals/footer/Footer'));
const FooterFunnel = lazy(() => import('./components/globals/footer/FooterFunnel'));
const Hotels = lazy(() => import('./components/pages/hotels/Hotels'));
const Gifts = lazy(() => import('./components/pages/gifts/Gifts'));
const GiftCard = lazy(() => import('./components/pages/gifts/giftCard/GiftCard'));
const GiftTravelType = lazy(() => import('./components/pages/gifts/giftTravelType/GiftTravelType'));
const GiftTravel = lazy(() => import('./components/pages/gifts/giftTravel/GiftTravel'));
const Contact = lazy(() => import('./components/pages/contact/Contact'));
const Airlines = lazy(() => import('./components/pages/airlines/Airlines'));
const HowItWorks = lazy(() => import('./components/pages/howItWorks/HowItWorks'));
const OurExperiences = lazy(() => import('./components/pages/ourExperiences/OurExperiences'));
const Help = lazy(() => import('./components/pages/help/Help'));
const Reviews = lazy(() => import('./components/pages/reviews/Reviews'));
const Privacy = lazy(() => import('./components/pages/terms/PrivacyPolicy'));
const Dates = lazy(() => import('./components/funnel/calendar/Calendar'));
const DiscardDestinations = lazy(() => import('./components/funnel/discardDestinations/DiscardDestinations'));
const Extras = lazy(() => import('./components/funnel/extras/Extras'));
const Passengers = lazy(() => import('./components/funnel/passengers/Passengers'));
const PassengersFillRemaining = lazy(() => import('./components/funnel/passengers/PassengersFillRemaining'));
const PlanInfo = lazy(() => import('./components/pages/planInfo/PlanInfo'));
const TypeOfDestination = lazy(() => import('./components/funnel/typeOfDestination/TypeOfDestination'));
const TypeOfActivity = lazy(() => import('./components/funnel/typeOfActivity/TypeOfActivity'));
const BestResult = lazy(() => import('./components/funnel/bestResult/BestResult'));
const ChooseFlight = lazy(() => import('./components/funnel/chooseFlight/ChooseFlight'));
const ChooseHotel = lazy(() => import('./components/funnel/chooseHotel/ChooseHotel'));
const HotelDetails = lazy(() => import('./components/funnel/hotelDetails/HotelDetails'));
const LandingGeneric = lazy(() => import('./components/pages/landings/landingGeneric/LandingGeneric'));
const LandingMarketing = lazy(() => import('./components/pages/landings/landingMarketing/LandingMarketing'));
const LandingB2B = lazy(() => import('./components/pages/landings/landingB2B/LandingB2B'));
const LandingEmployee = lazy(() => import('./components/pages/landings/landingEmployee/LandingEmployee'));
const LandingGifts = lazy(() => import('./components/pages/landings/landingGifts/LandingGifts'));
const LandingHalloween = lazy(() => import('./components/pages/landings/landingHalloween/LandingHalloween'));
const Feedback = lazy(() => import('./components/pages/feedback/Feedback'));
const Terms = lazy(() => import('./components/pages/terms/TermsOfUse'));
const Payment = lazy(() => import('./components/funnel/payment/Payment'));
const RecoverBooking = lazy(() => import('./components/funnel/recoverBooking/RecoverBooking'));
const AlmostThere = lazy(() => import('./components/funnel/almostThere/AlmostThere'));
const Congrats = lazy(() => import('./components/funnel/congrats/Congrats'));
const NotFound = lazy(() => import('./components/pages/notFound/NotFound'));
// const Crowdcube = lazy(() => import('./components/pages/landings/crowdcube/Crowdcube'));

function App() {
    const { routes, experiencesInfoRoutes, isFunnelRoute, isRouteWithFooter } = useRoutes();
    const { HelmetContainer } = useHelmet();
    const { planConfig } = usePlan();
    const { WhatsappWidget, showWidget } = useChatWidget();
    useTracking();
    useRakuten();

    useEffect(() => {
        document.documentElement.className = planConfig.slug;
        document.documentElement.style.setProperty('--planColorLight', planConfig.colors.light);
        document.documentElement.style.setProperty('--planColorDark', planConfig.colors.dark);
    }, [planConfig.id]);

    /*     useEffect(() => {
        dispatch(setCurrency('EUR'));
    }, []); */

    return (
        <>
            <HelmetContainer />
            <ScrollToTop />
            <Suspense fallback={<DummyHeader />}>
                <Header />
            </Suspense>
            <Suspense fallback={null}>
                <Routes>
                    <Route path={routes.home} element={<Home />} />
                    <Route path={routes.homeLocale} element={<Home />} />
                    <Route path={routes.flykubeHome} element={<Home />} />
                    <Route path={routes.aloyHome} element={<Home />} />
                    <Route path={routes.christmasHome} element={<Home />} />
                    {experiencesInfoRoutes.map((route) => (
                        <Route key={route} path={route} element={<PlanInfo />} />
                    ))}
                    <Route path={routes.help} element={<Help />} />
                    <Route path={routes.contact} element={<Contact />} />
                    <Route path={routes.hotels} element={<Hotels />} />
                    <Route path={routes.reviews} element={<Reviews />} />
                    <Route path={routes.airlines} element={<Airlines />} />
                    <Route path={routes.howItWorks} element={<HowItWorks />} />
                    <Route path={routes.ourExperiences} element={<OurExperiences />} />
                    <Route path={routes.gift} element={<Gifts />} />
                    <Route path={routes.giftCard} element={<GiftCard />} />
                    <Route path={routes.giftCardPayment} element={<Payment />} />
                    <Route path={routes.giftExperienceType} element={<GiftTravelType />} />
                    <Route path={routes.giftExperienceOptionsDestination} element={<GiftTravel planId={1} />} />
                    <Route path={routes.giftExperienceOptionsGetaway} element={<GiftTravel planId={3} />} />
                    <Route path={routes.giftExperienceOptionsSummer} element={<GiftTravel planId={4} />} />
                    <Route path={routes.giftExperienceOptionsNational} element={<GiftTravel planId={7} />} />
                    <Route path={routes.giftExperiencePayment} element={<Payment />} />
                    <Route path={routes.calendar} element={<Dates />} />
                    <Route path={routes.discardDestinations} element={<DiscardDestinations />} />
                    <Route path={routes.bestResult} element={<BestResult />} />
                    <Route path={routes.chooseFlight} element={<ChooseFlight />} />
                    <Route path={routes.chooseHotel} element={<ChooseHotel />} />
                    <Route path={routes.hotelDetails} element={<HotelDetails />} />
                    <Route path={routes.hotelPreview} element={<HotelDetails isPreview />} />
                    <Route path={routes.extras} element={<Extras />} />
                    <Route path={routes.passengers} element={<Passengers />} />
                    <Route path={routes.passengersFillRemaining} element={<PassengersFillRemaining />} />
                    <Route path={routes.payment} element={<Payment />} />
                    <Route path={routes.paymentConfirm} element={<PaymentConfirm />} />
                    <Route path={routes.almostThere} element={<AlmostThere />} />
                    <Route path={routes.almostThereRef} element={<AlmostThere />} />
                    <Route path={routes.congratsBooking} element={<Congrats />} />
                    <Route path={routes.congratsGiftCard} element={<Congrats />} />
                    <Route path={routes.congratsGiftTravel} element={<Congrats />} />
                    <Route path={routes.terms} element={<Terms />} />
                    <Route path={routes.privacy} element={<Privacy />} />
                    <Route path={routes.destinationType} element={<TypeOfDestination />} />
                    <Route path={routes.activityType} element={<TypeOfActivity />} />
                    <Route path={routes.landingGeneric} element={<LandingGeneric />} />
                    <Route path={routes.landingMarketing} element={<LandingMarketing />} />
                    <Route path={routes.landingB2B} element={<LandingB2B />} />
                    <Route path={routes.landingEmployee} element={<LandingEmployee />} />
                    <Route path={routes.landingGifts} element={<LandingGifts />} />
                    <Route path={routes.landingHalloween} element={<LandingHalloween />} />
                    <Route path={routes.feedback} element={<Feedback />} />
                    <Route path={routes.recoverBooking} element={<RecoverBooking />} />
                    {/* <Route path={routes.crowdcube} element={<Crowdcube />} /> */}
                    <Route path="/us/*" element={<CountryRedirect />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
                {isRouteWithFooter && <Footer />}
                {isFunnelRoute && <FooterFunnel />}
                {showWidget && <WhatsappWidget />}
            </Suspense>
        </>
    );
}

export default App;
